<template>
    <div>
        <Header></Header>
        <div class="content">
            <div class="msg-tabs">
                <div class="msg-header">
                    <span>消息中心</span>
                </div>
                <div class="msg-tabs-box">
                    <div class="tabs-item">私信</div>
                    <div class="tabs-item this">系统消息</div>
                </div>
            </div>
            <div class="msg_window" id="msg_window" v-if="list.length!=0">
                <div class="previous" v-if="isOK&&list.length!=0" @click="getdata">
                    点击加载上一页
                </div>
                <div class="msg_item" v-for="(item,index) in list">
                    <div class="item_date">
                        {{item.time}}
                    </div>
                    <div class="item_info">
                        <div class="item_img">
                            <img src="../../static/images/min_iocn44.png" alt="">
                        </div>
                        <div class="item_content">
                            {{item.body || ''}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="msg_window" v-if="list.length==''&&!isOK">
                <div>
                    <div class="iconfont icon-wushuju" style="font-size: 70px;color: var(--color);line-height: 100px;padding-top: 130px;text-align: center;"></div>
                    <div style="text-align:center;color:#999">暂无数据!</div>
                </div>
            </div>
        </div>
        <gap height="50"></gap>
        <Homefooter ></Homefooter>
    </div>
</template>

<script>
import { sourc, sourc2,typeMessages} from "@/apiUrl/index.js";
import { mapGetters } from "vuex";
export default {
    computed: {
        ...mapGetters(["userInfo", "config","login_show","token"]),
    },
    data() {
        return {
            sourc,
            sourc2,
            count:1,
            list:[],
            isOK:true,
        };
    },
    created() {
        this.getdata();
    },
    methods: {
        getdata(){
            typeMessages({
                type:0,
                pageSize:'12',
                pageNo:this.count,
                mid:this.userInfo.id || ''
            }).then((res)=>{
                if(res.code==200){
                    var arr = res.data.list || [];
                    arr.reverse();
                    if(this.count==1){
                        this.list = arr;
                        setTimeout(() => {
                            this.scroll_bom();
                        }, 1);
                    }else{
                        this.list = this.list.concat(arr);
                    }
                    if(this.list.length>=res.data.total){
                        this.isOK = false;
                    }else{
                        this.count++;
                        this.isOK = true;
                    }
                }else{
                    this.$msg(res.message);
                }
            })
        },
        scroll_bom(){
            var targetElement = document.getElementById("msg_window");
            targetElement.scrollTop = targetElement.scrollHeight;
        }
    },
};
</script>

<style lang="less" scoped>
    .content{
        margin-top: 16px;
        box-shadow: 0 0 2px 0 rgba(0,0,0,0.1);
        position: relative;
        background-color: #fff;
        padding-top: 24px;
        border-radius: 2px;
        min-height: 510px;
    }
    .msg-tabs {
        padding: 0 24px;
        box-sizing: border-box;
        span{
            font-size: 18px;
            color: #222226;
            line-height: 26px;
        }
    }
    .msg-tabs-box{
        margin-top: 8px;
        margin-bottom: 15px;
    }
    .tabs-item{
        line-height: 40px;
        display: inline-block;
        margin: 0 20px;
        height: 40px;
        font-size: 16px;
        font-weight: 400;
        color: #777888;
        border-bottom: 2px solid #fff;
        cursor: pointer;
    }
    .tabs-item:nth-child(1){
        margin-left: 0;
    }
    .tabs-item.this{
        border-bottom: 2px solid #222;
        color: #222;
    }
    .msg_window{
        width: 100%;
        height: 500px;
        border-top: 1px solid #eee;
        overflow-y: scroll;
        padding: 10px 24px;
        box-sizing: border-box;
    }
    .msg_window::-webkit-scrollbar {
        width: 4px;
        display: block;    
    }
    .msg_window::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 5px #fff;
        background: #fff;
    }
    .msg_window:hover::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
        background: rgba(0,0,0,0.1);
    }
    .msg_window::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #fff;
        border-radius: 0;
        background: #fff;
    }
    .msg_item{
        padding: 8px 0;
    }
    .item_date{
        text-align: center;
        font-size: 12px;
        padding-bottom: 8px;
        color: #999;
    }
    .item_info{
        display: flex;
    }
    .item_img{
        width: 40px;
        height: 40px;
        border-radius: 20px;
        overflow: hidden;
        background-color: #f5f5f5;
        padding: 4px;
        box-sizing: border-box;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .item_content{
        background-color: #f5f5f5;
        border-radius: 6px;
        line-height: 28px;
        padding: 4px 12px;
        max-width: 641px;
        font-size: 14px;
        font-weight: 400;
        color: #1c1c28;
        margin-left: 8px;
    }
    .previous{
        text-align: center;
        padding: 10px 0;
        color: var(--color);
        cursor: pointer;
    }
</style>
